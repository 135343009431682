<template>
  <!--
    Enables upload of theme files to the amazon cloud for terminal
  -->
  <div class="documentationStaticResourceUploader">
    <Portlet
      :title="$t('staticResourceUploaderComponent.documentationUploader')"
      icon="upload"
    >
      <div
        class="row"
      >
        <div class="col-12 col-xl-3 mb-3">
          <Portlet
            :title="$t('staticResourceUploaderComponent.upload')"
            icon="upload"
          >
            <div class="input-group mb-3">
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('type') }}</label>
              </div>
              <select
                v-model="documentationType"
                class="form-control cursor-pointer rounded"
                @change="uploadPercentage = 0"
              >
                <option value="">
                  {{ $t('pleaseSelectOne') }}
                </option>
                <option
                  v-for="(type, typeIndex) in documentationTypes"
                  :key="`docTypeSelectOption-${ typeIndex }`"
                  :value="type"
                >
                  {{ type }}
                </option>
              </select>
            </div>

            <div class="input-group mb-3">
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.files') }}</label>
              </div>
              <input
                ref="fileInput"
                type="file"
                :disabled="documentationType == ''"
                accept=".jpg,.jpeg,.png,.pdf"
                class="form-control cursor-pointer rounded"
                @change="getFileFromInput($event)"
              >
            </div>
            
            <div
              v-if="uploadPercentage > 0"
              class="input-group mb-3"
            >
              <div class="input-prepend mr-3">
                <label style="width:50px;">{{ $t('staticResourceUploaderComponent.progress') }}</label>
              </div>
              <div class="progress">
                <div
                  :aria-valuenow="uploadPercentage"
                  :style="`width:${ uploadPercentage }%;`"
                  :disabled="documentationType == '' && !file"
                  class="progress-bar"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ uploadPercentage }} %
                </div>
              </div>
            </div>
            
            <template v-if="uploadPercentage == 0">
              <button
                v-if="file != null"
                class="btn btn-primary btn-block"
                @click="saveFile()"
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="upload"
                />
                <span>
                  {{ $t('upload') }}
                </span>
              </button>
              <button
                v-else
                class="btn btn-primary btn-block cursor-not-allowed"
                disabled
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="upload"
                />
                <span>
                  {{ $t('upload') }}
                </span>
              </button>
            </template>
          </Portlet>
        </div>
        <div class="col-12 col-xl-9">
          <InstallationDocumentationList
            ref="docList"
            :installation-id="installationId"
            :documentation-type="'both'"
          />
        </div>
      </div>
    </Portlet>
  </div>
</template>

<script>
export default {
  name: "DocumentationStaticResourceUploader",
  components: {
    InstallationDocumentationList: () => import('@/components/InstallationDocumentation/InstallationDocumentationList.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      file: null,
      uploadPercentage: 0,
      documentationTypes: ['Scematic', 'Network Overview'],
      documentationType: ''
    }
  },
  methods: {
    getFileFromInput () {
      this.file = this.$refs.fileInput.files[0];
      this.uploadPercentage = 0;
    },
    saveFile () {
      this.uploadPercentage = 0;
      let bodyFormData = new FormData();
      
      bodyFormData.append("formFile", this.file);
      this.axios.post(`/StaticResource/Upload/${ this.installationId }?fileName=${ this.file.name }&documentationType=${ this.documentationType }`,
        bodyFormData,
        { headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function ( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      )
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'), this.$t('staticResourceUploaderComponent.fileSavedTitle'));
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.uploadPercentage = 0;
          this.$refs.docList.getData();
        }, 2500);
      });
    },
  }
}
</script>

<style>
.documentationStaticResourceUploader input[type="file"] {
  height: 40px;
  padding-top: 8px;
  padding-left: 8px;
}
.documentationStaticResourceUploader .previewImage {
  width: 100%;
  height: auto;
  display: block;
}
.documentationStaticResourceUploader .placeholderIcon {
  font-size: 2rem;
}
.documentationStaticResourceUploader .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
